<template>
  <div class="compositiondropdown_box mt-0 d-flex align-items-center">
    <h6> {{ title }} </h6>
    <spin-button v-model="count" :max="max" :min="min"/>
  </div>
</template>

<script>
export default {
  name: 'selection-count',
  components: {
    SpinButton: () => import('@/sabre/common/atom/spinPlusMinusButton'),
  },
  props: ['modelValue', 'title', 'range'],
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  computed: {
    max() {
      const { range } = this;
      return range ? range.max : 10;
    },
    min() {
      const { range } = this;
      return range ? range.min : 0;
    },
  },
  watch: {
    count() {
      this.$emit('change', this.count);
    },
    modelValue() {
      this.count = this.modelValue;
    },
  },
  data() {
    return {
      count: this.modelValue,
    };
  },
};
</script>
